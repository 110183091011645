@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");

body {
  margin: 0;
  font-family: "Poppins", sans-serif;
}
.sitecontent img {
  display: initial;
}
.sitecontent h1 {
  font-size: 28px;
  font-weight: bold;
}

.sitecontent h2 {
  font-size: 24px;
  font-weight: bold;
}
.sitecontent h3 {
  font-size: 20px;
  font-weight: bold;
}
.sitecontent h4 {
  font-size: 18px;
  font-weight: bold;
}
.sitecontent h5 {
  font-size: 16px;
  font-weight: bold;
}
.sitecontent ul li {
  position: relative;
  padding: 0 0 7px 15px;
}
.sitecontent ul li::before {
  content: url("/public/images/home/arrow.svg");
  position: absolute;
  left: 0;
  width: 7px;
  top: -1px;
}
.sitecontent p {
  font-size: 14px;
  line-height: 1.8;
  padding: 0 0 15px;
}
.site-header {
  padding: 20px 0;
  float: left;
  width: 100%;
}
.siteWidth {
  max-width: 1195px;
  padding: 0 15px;
  margin: 0 auto;
}

.btn-main {
  padding: 10px 40px;
  color: #fff;
  background: #6c78d0;
  border-radius: 30px;
  border: 1px solid #6c78d0;
  transition: 0.2s all ease;
  display: inline-block;
}
.btn-main:hover {
  background-color: #fff;
  color: #000;
}
.btn-main-sm {
  padding: 3px 15px;
}

.btn-main-white {
  padding: 10px 40px;
  color: #000;
  background: #fff;
  border-radius: 30px;
  border: 1px solid #6c78d0;
  transition: 0.2s all ease;
}
.btn-main-white:hover {
  background-color: #6c78d0;
  color: #fff;
}

.title-hd {
  text-align: center;
  font-size: 20px;
  font-weight: bold;
}
.title-hd span {
  display: inline-block;
  border: 2px solid #6c78d0;
  border-radius: 50px;
  padding: 5px 25px;
}

.border-hd {
  position: relative;
  padding-bottom: 7px;
}
.border-hd::after {
  width: 50px;
  height: 5px;
  background: #6c78d0;
  content: "";
  position: absolute;
  left: 0;
  bottom: 0px;
}
.react-tabs ul.react-tabs__tab-list {
  padding: 0 0 20px;
}
.react-tabs ul.react-tabs__tab-list li.react-tabs__tab {
  display: inline;
  border: 1px solid #000;
  padding: 7px 15px;
  border-radius: 40px;
  margin-right: 4px;
  font-size: 15px;
  position: relative;
}
.react-tabs ul.react-tabs__tab-list li.react-tabs__tab::before {
  display: none;
}
.react-tabs ul.react-tabs__tab-list li.react-tabs__tab--selected {
  background: #6c78d0;
  color: #fff;
}
.react-tabs ul.react-tabs__tab-list li.react-tabs__tab--selected::after {
  content: "";
  position: absolute;
  width: 15px;
  height: 15px;
  background: #6c78d0;
  border-left: 1px solid #000;
  border-bottom: 1px solid #000;
  transform: rotate(-45deg);
  bottom: -8px;
  left: 0;
  right: 0;
  margin: 0 auto;
}
.react-tabs__tab-panel--selected {
  border: 1px solid #6c78d0;
  padding: 20px;
  border-radius: 20px;
}
.form-group label {
  display: block;
  padding: 0 0 3px;
}
.form-group input.form-control,
.form-group textarea.form-control,
.form-group select.form-control {
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 7px 0 7px 10px;
  margin: 0 0 15px;
  transition: 0.2s all ease;
}
.form-group input.form-control:focus {
  border: 1px solid #6c78d0;
}
.form-group button.form-control {
  background: #6c78d0;
  padding: 7px 15px;
  border-radius: 10px;
  color: #fff;
  transition: 0.2s all ease;
}
.form-group button.form-control:hover {
  background: #3c3c3c;
}
/** Header **/
.site-header .logo {
  float: left;
  width: 100px;
  position: absolute;
  top: 0;
}
.main-navigation {
  float: right;
}
.main-navigation ul li {
  float: left;
  position: relative;
}
.main-navigation ul li::before {
  display: none;
}
.main-navigation ul li.has-child span.sub-icon {
  margin-left: -7px;
  position: relative;
  top: 1px;
}

.main-navigation ul li a {
  display: inline-block;
  padding: 16px 15px;
  font-weight: 600;
  font-size: 16px;
  transition: 0.2s all ease;
}
.main-navigation ul li a:hover {
  color: #6c78d0;
}
.main-navigation ul li ul.sub-menu {
  position: absolute;
  width: 300px;
  top: 84px;
  border: 1px solid #6c78d0;
  border-radius: 10px;
  left: 50%;
  transform: translateX(-50%);
  border-top: 13px solid #6c78d0;
  visibility: hidden;
  opacity: 0;
  transition: 0.2s all ease;
  background: #fff;
  z-index: 9999;
}
.main-navigation ul li:hover ul.sub-menu {
  opacity: 1;
  visibility: visible;
  top: 64px;
}
.main-navigation ul li ul.sub-menu::before {
  width: 15px;
  height: 15px;
  transform: rotate(45deg);
  background-color: #6c78d0;
  position: absolute;
  content: "";
  left: 0;
  right: 0;
  margin: 0 auto;
  top: -20px;
}
.main-navigation ul li ul.sub-menu li {
  width: 100%;
}
.main-navigation ul li ul.sub-menu li a {
  padding: 10px 15px;
  display: block;
}

.main-navigation ul li ul.sub-menu li .n_icon {
  float: right;
  color: #6c78d0;
  font-size: 28px;
  line-height: 0;
}
.main-navigation ul li ul.sub-menu li a:hover {
  background-color: #6c78d0;
  color: #fff;
}
.main-navigation ul li ul.sub-menu li a:hover span {
  color: #fff;
}

.main-navigation ul li.price_btn {
  margin-left: 10px;
}
.main-navigation ul li.price_btn a {
  margin: 3px 0 0;
  padding: 10px 40px;
  color: #fff;
  background: #6c78d0;
  border-radius: 30px;
  border: 1px solid #6c78d0;
}
.main-navigation ul li.price_btn a:hover {
  background: #fff;
  color: #000;
}

.email-us-btn {
  text-align: center;
  background: #6c78d0;
  padding: 30px;
  margin: 50px 0;
  border-top: 1px solid #ccc;
}
.email-us-btn a {
  font-size: 24px;
  color: #fff;
  font-weight: bold;
}
/**** Footer ***/
.site-footer {
  width: 100%;
  float: left;
  padding: 50px 0 0;
}
.mainFooter {
  float: left;
  width: 100%;
}

.site-footer .footer-left {
  max-width: 360px;
  float: left;
  margin-right: 80px;
}
.site-footer .footer-right {
  float: right;
  width: 725px;
}
.footer-1 .logo {
  max-width: 120px;
  display: inline-block;
  padding: 0 15px 0 0;
  border-right: 1px solid #ccc;
}
.foot-awards {
  display: inline-block;
  vertical-align: top;
}
.foot-awards img {
  max-width: 57px;
  display: inline-block;
  margin: 23px 11px;
}

.footer-1 .pay-sec {
  font-size: 20px;
  font-weight: 600;
  display: inline-block;
  background: #6c78d0;
  padding: 7px 45px;
  border-radius: 50px;
  color: #fff;
}

.foot-social .social-icons a {
  font-size: 20px;
  display: inline-block;
  margin-right: 60px;
}
.footer-right-top {
  display: flex;
  justify-content: space-between;
}
.footer-clients {
  width: 100%;
  float: left;
  padding: 20px 0 0;
}
.footer-clients h6 {
  font-weight: bold;
}
.footer-clients-logos {
  display: flex;
  gap: 10px;
  padding: 15px 0 0;
}
.footer-clients-logos img {
  max-width: 25%;
  border: 1px solid #efefef;
}
.site-footer .foot-title {
  font-size: 24px;
  font-weight: bold;
  padding: 0 0 30px;
}
.site-footer .foot-menu ul li {
  padding: 0 0 20px;
}
.footer-bottom {
  float: left;
  width: 100%;
  padding: 30px 0;
  margin: 50px 0 0;
  border-top: 1px solid #ccc;
}
.footer-bottom .copyright {
  float: left;
}
.footer-bottom .dcma_status {
  float: right;
}
.whatsapp {
  position: fixed;
  bottom: 30px;
  left: 25px;
}
.whatsapp .icon {
  background: #1ab99f;
  padding: 10px;
  border-radius: 100px;
}

.whatsapp .icon svg {
  font-size: 36px;
  color: #ffffff;
}
/* Home page */

.head-section {
  float: left;
  width: 100%;
  padding: 50px 0 0;
}
.head-section .head-section-left {
  float: left;
  width: 50%;
  padding: 30px 0 0;
}
.head-section .head-section-left h1 {
  font-size: 52px;
  font-weight: bold;
  line-height: 59px;
}
.head-section .head-section-left h1 span {
  color: #6c78d0;
}
.head-section .head-section-left p {
  padding: 20px 0 0;
  line-height: 30px;
}
.head-section .head-section-left .get_p {
  margin: 20px 0 0;
  padding: 15px 25px;
  box-shadow: 6px 5px 15px 4px #8c96e12b;
  display: inline-block;
  border-radius: 20px;
}
.head-section .head-section-left .get_p a {
  display: inline-block;
  background: #2e3b99;
}
.head-section .head-section-left a.btn-main-white {
  background: #827fbd;
  color: #fff;
}
.head-section .head-section-left .get_p a:hover {
  background: #fff;
  color: #000;
}
.head-section-right {
  float: right;
  width: 50%;
  display: flex;
  justify-content: end;
  padding: 30px 0 0;
}
.head-section-right-form {
  padding: 30px;
  width: 460px;
  background: #341449;
  border-radius: 15px;
}
.head-section-right-form h2 {
  padding: 0 0 10px;
  color: #fff;
  font-size: 36px;
}
.head-section-right-form .form-control {
  background: #2c1a62;
  width: 100%;
  border: 1px solid #827fbd;
  padding: 7px 15px;
  margin: 0 0 10px;
  border-radius: 5px;
  color: #fff;
}
.head-section-right-form button {
  background: #fff;
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  transition: 0.2s all ease;
}
.head-section-right-form button:hover {
  background: #6c78d0;
  color: #fff;
}
.head-section-right img {
  display: inline-block;
}

.home-about {
  text-align: center;
  float: left;
  width: 100%;
  margin: 40px 0 0;
  padding: 30px 0;
  background: #f4f5ff;
}
.home-about h1 {
  font-size: 24px;
  font-weight: bold;
  padding: 0 0 10px;
}

.home-achivments {
  float: left;
  width: 100%;
  margin: 50px 0 0;
}

.home-achiv-box {
  float: left;
  width: 100%;
  margin: 30px 0 0;
}
.home-achiv {
  float: left;
  width: 25%;
  padding: 0 15px;
}
.home-achiv-in {
  text-align: center;
  box-shadow: 6px 5px 15px 4px #8c96e142;
  border-radius: 20px;
  padding: 30px;
}
.home-achiv-in .ac_icon {
  font-size: 36px;
  color: #6c78d0;
}
.home-achiv-in .ac_text p {
  font-size: 30px;
}
.home-achiv-in .ac_text p span {
  font-size: 18px;
  vertical-align: top;
}

.home-business {
  float: left;
  width: 100%;
  margin: 70px 0 0;
}

.home-business-box-left {
  float: left;
  width: 53%;
  padding: 30px 0 0;
}
.home-business-services {
  float: left;
  width: 100%;
  padding: 30px 0 0;
}
.home-business-box-left h2 {
  font-size: 24px;
  font-weight: bold;
  margin: 10px 6px 0;
}
.home-business-box-left p {
  padding: 15px 6px 0;
}
.hb_service {
  float: left;
  width: 50%;
  padding: 0 6px 20px;
}
.hb_service-in {
  padding: 7px 20px;
  border: 1px solid #6c78d0;
  border-radius: 80px;
  transition: 0.2s all ease;
  box-shadow: 6px 5px 10px 4px #8c96e142;
}
.hb_service-in span {
  font-size: 20px;
  transition: 0.2s all ease;
}
.hb_service-in p {
  font-size: 15px;
  display: inline;
  line-height: 3px;
  padding: 0 0 0 10px;
  transition: 0.2s all ease;
}
.hb_service-in:hover {
  background: #6c78d0;
}
.hb_service-in:hover p,
.hb_service-in:hover span {
  color: #fff;
}
.home-business-box-right {
  text-align: center;
  float: right;
  width: 47%;
}
.home-business-box-right img {
  display: inline-block;
}

.home-concepts {
  float: left;
  width: 100%;
  margin: 80px 0 0;
  padding: 40px 0;
  background: #f4f5ff;
}
.home-concepts-main {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: repeat(5, 20%);
}
.home-concepts-box {
  padding: 0 15px;
  height: 100%;
}
.home-concepts-box-in {
  text-align: center;
  box-shadow: 6px 5px 10px 4px #8c96e142;
  padding: 20px 10px;
  border-radius: 20px;
  height: 100%;
  background: #fff;
}
.home-concepts-box-in span.hc_icon span {
  display: block;
  font-size: 50px;
  color: #6c78d0;
}
.home-concepts-box-in span.hc_icon {
  font-size: 20px;
}
.home-concepts-box-in p {
  font-size: 15px;
  padding: 15px 0 0;
}

.home-design-dev {
  float: left;
  width: 100%;
  padding: 60px 0 0;
}
.home-design-dev-left {
  width: 43%;
  float: left;
}
.home-design-dev-right {
  float: right;
  width: 53%;
  padding: 30px 0 0;
}

.home-design-dev-right p {
  padding: 15px 0 0;
}
.home-design-dev-right .hb_service-in p {
  padding: 0px 0 0 10px;
}

.home-tabs {
  float: left;
  width: 100%;
  padding: 60px 0 0;
}
.tab-content-top p {
  font-size: 15px;
  margin: 0 0 20px;
  border-left: 5px solid #6c78d0;
  padding: 0 0 0 10px;
}
.tab-content-bottom h3 {
  padding: 0 0 10px;
}
.tab-content-bottom p {
  display: inline-block;
  width: 24%;
  text-align: center;
  padding: 5px 15px;
  background: #f4f5ff;
  border-radius: 100px;
  margin: 0 5px;
  border: 1px solid #c1c7fc;
  font-size: 15px;
}
.tab-content-bottom ul li {
  display: inline-block;
  text-align: center;
  padding: 5px 15px;
  background: #f4f5ff;
  border-radius: 100px;
  margin: 0 10px 10px 0;
  border: 1px solid #c1c7fc;
  font-size: 15px;
}
.tab-content-bottom ul li::before {
  display: none;
}

.home-tech {
  float: left;
  width: 100%;
  margin: 60px 0 0;
  padding: 40px 0;
  background: #f4f5ff;
}

.home-tech-box {
  padding: 30px 0 0;
}

.htb_icon {
  float: left;
  width: 14.2%;
  text-align: center;
  font-size: 100px;
}
.htb_icon .fa-laravel {
  color: red;
}
.htb_icon .fa-react {
  color: #12a0c7;
}
.htb_icon .fa-angular {
  color: #bd002e;
}

.htb_icon .fa-android {
  color: #3bd580;
}
.htb_icon .fa-apple {
  color: #3c3c3c;
}

.htb_icon .fa-wordpress {
  color: #207196;
}

.htb_icon .fa-php {
  color: #7377ad;
}

/* Home page end */

.inner-page {
  float: left;
  width: 100%;
  padding: 30px 0 0;
}
.sitecontent {
  float: left;
  width: 100%;
}
/* About page */
.about-us-page-header {
  text-align: center;
  padding: 30px 0 0;
  float: left;
  width: 100%;
}
.about-us-page-header h1 {
  font-size: 60px;
  padding: 0 0 15px;
}

.about-whoweare {
  float: left;
  width: 100%;
  padding: 40px 0 0;
}

.about-what-we-offer {
  float: left;
  width: 100%;
  margin: 50px 0 0;
  padding: 40px 0;
  background: #f4f5ff;
}
.about-whoweare-left {
  float: left;
  width: 30%;
  padding: 15px 0 0;
}
.about-whoweare-left h2 {
  font-size: 30px;
  padding: 0 0 7px;
}

.about-whoweare-right {
  float: right;
  width: 70%;
  padding: 0 0 0 40px;
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: repeat(3, 33%);
}
.awr-box {
  padding: 0 7px;
  height: 100%;
}
.awr-box-in {
  text-align: center;
  box-shadow: 6px 5px 15px 4px #8c96e142;
  border-radius: 20px;
  padding: 20px;
  background: #fff;
  height: 100%;
}
.awr-box-icon {
  font-size: 42px;
  color: #6c78d0;
}

.about-tech-story {
  float: left;
  width: 100%;
  padding: 60px 0 0;
  text-align: center;
}

.about-tech-cal {
  padding: 70px 0 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.about-tech-cal-box {
  padding: 0 15px;
  width: 33.33%;
  margin: 0 0 90px;
}
.about-tech-cal-box-in {
  box-shadow: 6px 5px 15px 4px #8c96e142;
  border-radius: 20px;
  padding: 20px;
  background: #fff;
  height: 100%;
  position: relative;
}
.about-tech-cal-box-in h4 {
  font-size: 40px;
  position: absolute;
  width: 100%;
  left: 0;
  top: -40px;
}

.about-tech-cal-box-in h4 span {
  background: #6c78d0;
  color: #fff;
  border-radius: 60px;
  padding: 7px 20px;
}
.about-tech-cal-box-in p {
  padding: 20px 0 0;
}

.about-mission {
  float: left;
  width: 100%;
  padding: 40px 0 0;
}
.about-mission h2 {
  padding: 0 0 10px;
}
.about-mission p {
  padding: 0 0 20px;
}

.mobile-app-content {
  padding: 20px 0 0;
}
.mobile-app-content h3 {
  padding: 0 0 7px;
}
.mobile-app-content p {
  padding: 0 0 20px;
}

.contact-page-left {
  float: left;
  width: 30%;
  padding: 20px;
  margin: 30px 0 0;
  background: #f4f5ff;
  border-radius: 20px;
}

.contact-page-right {
  float: right;
  width: 68%;
  margin: 30px 0 0;
}
.more-contact {
  float: left;
  width: 100%;
  padding: 15px 0 0;
}
.more-contact-box {
  width: 50%;
  display: inline-block;
  padding: 0 0 20px;
  vertical-align: top;
}
.more-contact-box a {
  display: block;
}

.locations {
  border-top: 1px solid #dfdfdf;
  float: left;
  width: 100%;
  padding: 20px 0 0;
}
.loaction-box {
  float: left;
  width: 50%;
}
.loaction-box p {
  padding: 0 0 5px;
}

/* business solution */

.business-solutions .hero-image {
  background: url("./assets/images/business-solution/hero-1-2.jpg") top fixed;
  background-size: cover;
}
.digital-marketing-content .hero-image {
  background: url("./assets/images/business-solution/hero-1-3.webp") top fixed;
}
.mobile-app .hero-image {
  background: url("./assets/images/business-solution/hero-1-4.webp") top fixed;
}

.hero-image-box {
  display: flex;
  align-items: center;
  height: 450px;
  flex-wrap: wrap;
}
.hero-image-box-inner {
  display: flex;
  gap: 50px;
  width: 100%;
  align-items: center;
  margin: 0 0 50px;
}
.hero-image-box-left {
  width: 70%;
}
.hero-image-right {
  padding: 15px 20px;
  width: 40%;
  background: #213752bd;
  border: 2px solid #4a6181;
  border-radius: 20px;
}
.hero-image-right-form .form-control {
  width: 100%;
  padding: 7px 10px;
  background: #1d324e;
  border: 1px solid #445976;
  margin: 0 0 10px;
  font-size: 14px;
  color: #fff;
}
.hero-image-right-form button {
  background: #fff;
  width: 100%;
  padding: 5px;
  border-radius: 5px;
  transition: 0.2s all ease;
}
.hero-image-right-form button:hover {
  background: #7377ad;
  color: #fff;
}
.hero-image-box h1 {
  color: #fff;
  text-transform: uppercase;
  font-size: 34px;
  display: flex;
  align-items: center;
  gap: 15px;
}

.hero-image-box h1 span {
  border: 1px solid #fff;
  border-radius: 5px;
  font-size: 12px;
  padding: 5px 10px;
  font-weight: 600;
  letter-spacing: 1px;
}

.hero-image-box h3,
.hero-image-box h2 {
  font-size: 26px;
  color: #fff;
  padding: 5px 0 0;
}

.hero-image-box p {
  font-size: 14px;
  color: #fff;
  padding: 10px 0 0;
}
.hero-image-box p.withbg {
  background: #1ab99f;
  padding: 10px 15px;
  display: inline-block;
  margin: 7px 0 0;
}
.business-development-top {
  float: left;
  width: 100%;
}
.business-development-top-boxes {
  display: flex;
  gap: 30px;
  margin: -60px 0 0;
}

.business-development-package-box {
  margin: 60px 0 0;
}

.business-development-top-boxes .box-inner {
  background: #fff;
  box-shadow: 0 0 20px #ccc;
  padding: 40px;
  border-radius: 10px;
  text-align: center;
  width: 33%;
}

.business-development-top-boxes .box-inner h2 {
  padding: 15px 0 5px;
  font-size: 22px;
}
.business-development-top-boxes .box-inner p {
  color: #7c7c7c;
  font-size: 15px;
  line-height: 1.8;
}

.business-development-package-box-large {
  margin: 60px 0 0;
  float: left;
  width: 100%;
}
.business-development-package-box-large h2 {
  font-size: 32px;
  color: #6c78d0;
  text-align: center;
}
.bdpbl-in {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  box-shadow: 0 0 10px #ccc;
  padding: 20px;
  border-radius: 10px;
}

.bdpbl-box {
  width: 23.6%;
  border: 2px solid #ededed;
  padding: 20px 0px 0;
  border-radius: 10px;
}
.bdpbl-box-header {
  text-align: center;
  min-height: 237px;
}
.bdpbl-box .price-box {
  border-radius: 10px 10px 0 0;
}
.bdpbl-box h3 {
  font-size: 27px;
  line-height: 1.3;
  padding: 0 0 10px;
}
.bdpbl-box-header p {
  padding: 0;
}
.bdpbl-box-acc h4 {
  padding: 0 0 15px;
  cursor: pointer;
}
.bdpbl-box-acc ul {
  max-height: 0px;
  overflow: hidden;
  transition: 0.2s all ease;
}
.showinc {
  max-height: 1500px !important;
}
.bdpbl-box-header-price p {
  font-weight: bold;
  font-size: 46px;
  align-items: flex-start;
  display: flex;
  justify-content: center;
  gap: 3px;
  padding: 5px 0 0;
  line-height: normal;
  color: #5665cf;
}
.bdpbl-box-header-price span.usdprice {
  font-size: 25px;
  font-weight: bold;
  line-height: 1;
}
.bdpbl-box-header-price span {
  font-size: 29px;
  line-height: 1.8;
}
.bdpbl-box-body {
  padding: 20px 30px;
  min-height: 350px;
}
.bdpbl-box-body ul li {
  padding: 0 0 10px 15px;
  font-size: 15px;
}
.bdpbl-box-acc {
  padding: 0 30px;
}
.bdpbl-box-footer {
  border-top: 2px solid #ededed;
  padding: 20px 30px;
}
.bdpbl-box-footer h6 {
  font-weight: bold;
}
.bdpbl-box-footer a {
  font-size: 18px;
  display: flex;
  gap: 5px;
  align-items: center;
  background: #1bbb2d;
  padding: 8px;
  justify-content: center;
  border-radius: 50px;
  color: #fff;
  align-items: center;
  position: relative;
  overflow: hidden;
}
.bdpbl-box-footer a::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -100%;
  height: 100%;
  width: 100%;
  background: #000;
  transition: 0.2s all ease;
}
.bdpbl-box-footer a:hover::after {
  bottom: 0;
}
.bdpbl-box-footer a span.icon {
  line-height: 1;
  position: relative;
  z-index: 999;
  color: #fff;
}
.business-development-package-box .box-inner {
  display: flex;
  gap: 30px;
  flex-wrap: wrap;
}
.business-development-package-box .box-inner .left {
  width: 40%;
}

.business-development-package-box .box-inner .left h3 {
  font-size: 32px;
  color: #6c78d0;
  line-height: 1.3;
  opacity: 0.8;
}

.business-development-package-box .box-inner .right {
  width: 57%;
}

.business-development-package-box .box-inner .left h4 {
  font-size: 15px;
  padding: 10px 0;
}

.business-development-package-box .box-inner .left ul li {
  font-size: 14px;
  padding: 10px 0;
  border-bottom: 1px solid #bec4f3;
  display: flex;
  gap: 8px;
}
.business-development-package-box .box-inner .left ul li:first-child {
  padding: 0px 0 10px;
}
.business-development-package-box .box-inner .left ul li::before {
  display: none;
}
.business-development-package-box .box-inner .left span.icon svg {
  font-size: 19px;
  fill: #6c78d0;
}
.MuiCollapse-vertical {
  background: #eff0ff;
}
.sitecontent .Mui-expanded {
  margin: 0 !important;
}
.silver-plan {
  background: rgb(241, 241, 241);
  background: linear-gradient(
    90deg,
    rgba(241, 241, 241, 1) 0%,
    rgba(156, 39, 176, 0) 100%
  );
}

.gold-plan {
  background: rgb(251, 233, 161);
  background: linear-gradient(
    90deg,
    rgba(251, 233, 161, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
}

.platinum-plan {
  background: rgb(171, 203, 204);
  background: linear-gradient(
    90deg,
    rgba(171, 203, 204, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
}

.diamond-plan {
  background: rgb(165, 202, 230);
  background: linear-gradient(
    90deg,
    rgba(165, 202, 230, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
}

.bd-contact {
  display: flex;
  width: 380px;
  gap: 24px;
  align-items: center;
}
.bd-contact-left {
  padding: 15px;
  background: #e7e8f1;
  border-radius: 5px;
}
.bd-contact-left svg {
  font-size: 36px;
  color: #6c78d0;
}
.bd-contact-right p {
  font-size: 20px;
}

.business-development-mid {
  padding: 60px 0 0;
  float: left;
  width: 100%;
}
.business-development-mid-inner {
  display: flex;
  gap: 30px;
  justify-content: space-between;
  text-align: center;
}

.business-development-mid-inner .bdmin {
  padding: 20px 40px;
  border: 1px solid #dfdfdf;
  border-radius: 10px;
  width: 33%;
}
.business-development-mid-inner .bdmin-img {
  width: 150px;
  margin: 0 auto;
  padding: 0 0 20px;
}
.business-development-mid-inner .bdmin-body h4 {
  padding: 10px 0 20px;
  font-size: 24px;
}

.business-solutions-content-main {
  padding: 50px 0 0;
}

.digital-marketing-content .business-development-top-boxes {
  margin: 40px 0 0;
}

.react-tabs ul.react-tabs__tab-list li.react-tabs__tab {
  cursor: pointer;
}
.seo-package-tab-inner .right {
  display: flex;
  justify-content: space-between;
  width: 70%;
}
.seo-package-box {
  width: 100%;
  float: left;
  margin: 40px 0 0;
}
.seo-package-tab {
  padding: 0px 0 10px;
}
.seo-package-tab .react-tabs__tab-panel {
  padding: 0;
  border-radius: 0;
}
.seo-package-tab-inner {
  display: flex;
}
.seo-package-tab-inner .left {
  width: 30%;
}

.seo-package-tab .tab-content {
  display: flex;
}

.seo-package-tab .tab-content .left {
  width: 30%;
}
.seo-package-tab .tab-content .right {
  width: 70%;
  display: flex;
}
.seo-package-tab .tab-content .right .digi-price-tag {
  width: 20%;
  text-align: center;
  font-size: 30px;
  font-weight: bold;
  border-left: 1px solid #a9a9a9;
  padding: 20px;
  line-height: 1;
}
.seo-package-tab .tab-content .right .digi-price-tag p {
  font-size: 12px;
  padding: 3px 15px;
  border-radius: 100px;
  display: inline-block;
  margin: 0;
  line-height: 1;
  color: #fff;
  font-weight: normal;
}

.seo-package-tab .tab-content .right .price-1 p {
  background: #f03a25;
}
.seo-package-tab .tab-content .right .price-2 p {
  background: #f58833;
}

.seo-package-tab .tab-content .right .price-3 p {
  background: #2288c6;
}

.seo-package-tab .tab-content .right .price-4 p {
  background: #dfc856;
}
.seo-package-tab .tab-content .right .price-5 p {
  background: #83b03b;
}

.seo-package-tab .tab-content .right .digi-price-tag h4 {
  font-size: 35px;
  display: block;
  margin: 0;
  padding: 15px 0;
  line-height: 1;
}

.seo-package-tab .tab-content .right .digi-price-tag span {
  font-size: 14px;
  background: #1ab99f;
  display: block;
  border-radius: 100px;
  color: #fff;
  font-weight: normal;
  padding: 5px;
  cursor: pointer;
  transition: 0.2s all ease;
}

.seo-package-tab .tab-content .right .digi-price-tag span:hover {
  background: #14d4b4;
}
.seo-package-tab .tab-content .right .digi-price-tag span:focus {
  background: #09705f;
}

.seo-packages-list-box h3 {
  text-align: center;
  padding: 5px;
  background: #b0b6db;
  color: #fff;
  font-weight: normal;
}

.seo-packages-list-box-inner {
  display: flex;
  border: 1px solid #a9a9a9;
  border-top: none;
}
.seo-packages-list-box-inner .icon {
  display: inline-block;
  padding: 0 15px 0;
}
.seo-packages-list-box-inner .icon svg {
  font-size: 18px;
  color: #5665cf;
}
.seo-packages-list-box-inner .box-1 {
  width: 30%;
  font-size: 14px;
  padding: 10px 0;
  display: flex;
}
.seo-packages-list-box-inner .box-2 {
  width: 14%;
  font-size: 14px;
  text-align: center;
  padding: 10px 0;
  border-left: 1px solid #a9a9a9;
}

.mid-form h3 {
  font-size: 26px;
  padding: 0 0 15px;
}
.react-tabs ul.react-tabs__tab-list li.react-tabs__tab {
  text-align: center;
}
.mobile-nav,
.float-icon,
.mob-contact {
  display: none;
}

.sh-title .title {
  font-size: 32px;
}

.sh-title span {
  width: 80px;
  height: 6px;
  background: #6a7acf;
  display: inline-block;
  border-radius: 200px;
}

/* Mobile app development */

.mobile-header-content {
  display: flex;
  gap: 50px;
  flex-wrap: wrap;
  padding: 0 0 50px;
}
.mobile-header-left {
  width: 53%;
}
.mobile-header-left h1 {
  font-size: 45px;
  line-height: 1.2;
}
.mobile-header-left p {
  font-size: 20px;
  line-height: 1.8;
  margin: 20px 0 0;
}
.mobile-header-left btn-box {
  margin: 20px 0 0;
}
.mobile-header-right {
  width: 42%;
}

.mobile-content-sec-1 {
  padding: 40px 0;
  background: #f5f6f8;
}

.mobile-content-sec-1-boxes {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  justify-content: space-between;
  padding: 50px 0 0;
}

.mobile-content-sec-1-boxes .box {
  width: 31.6%;
  padding: 30px;
  background: #fff;
  border-radius: 10px;
  transition: 0.2s ease all;
}
.mobile-content-sec-1-boxes .box:hover {
  box-shadow: 0 0 50px #00000026;
}
.mobile-content-sec-1-boxes .box .box-head {
  display: flex;
  gap: 15px;
  padding: 0 0 15px;
}
.mobile-content-sec-1-boxes .box .img {
  width: 100px;
}
.mobile-content-sec-1-boxes .box .box-head h3 {
  font-size: 24px;
}

.mobile-content-sec-1-boxes .box .box-body p {
  padding: 0;
}

.mobile-content-sec-2 {
  margin: 50px 0 0;
  text-align: center;
  border: 10px solid #6a7acf;
  padding: 50px;
}

.mobile-content-sec-2 h2 {
  font-size: 28px;
  padding: 0 0 10px;
}

.mobile-content-sec-3 {
  margin: 80px 0 0;
}

.mobile-content-sec-3-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.mobile-content-sec-3-content .left {
  width: 59%;
}
.mobile-content-sec-3-content .left .sh-title h2 {
  font-size: 42px;
}

.mobile-content-sec-3-content .right {
  width: 40%;
  border: 1px solid #dfdfdf;
  padding: 30px;
  border-radius: 10px;
  text-align: center;
}

.mobile-content-sec-4 {
  margin: 60px 0 0;
  padding: 40px 0;
  background: #f9f9f9;
}

.mobile-content-sec-4-box {
  margin: 40px 0 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.mobile-content-sec-4-box .box {
  width: 32%;
}

.mobile-content-sec-4-box .img {
  overflow: hidden;
}
.mobile-content-sec-4-box .img img {
  width: 100%;
  transition: 0.4s ease all;
}

.mobile-content-sec-4-box .box:hover .img img {
  transform: scale(1.2);
}

.mobile-content-sec-4-box .title-box {
  display: flex;
  justify-content: space-between;
  padding: 30px 0 0;
}

.mobile-content-sec-4-box .title-box .title {
  font-size: 22px;
  padding: 0;
  line-height: 1;
}
.mobile-content-sec-4-box .title-box .sh-title span {
  width: 50px;
  height: 3px;
}

.mobile-content-sec-5 {
  margin: 60px 0 0;
}
.mobile-content-sec-5-box {
  margin: 20px 0 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.mobile-content-sec-6 {
  margin: 80px 0 0;
}

.mobile-content-sec-6-box {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  margin: 30px 0 0;
}

.mobile-content-sec-6-box .box {
  width: 31.6%;
  padding: 25px;
  border: 1px solid #dddddd;
  background: #f9f9f9;
}
.mobile-content-sec-6-box .box .big-text {
  font-size: 45px;
  font-weight: bold;
  color: #5665cf;
}

.mobile-content-sec-7 {
  margin: 60px 0 0;
}

.mobile-content-sec-7-box {
  display: flex;
  margin: 30px 0;
  padding: 30px;
  box-shadow: 0 0 50px #dbdbdb;
  border-radius: 10px;
  text-align: center;
}
.mobile-content-sec-7-box .box {
  border-left: 1px solid #ddd;
  padding: 0 15px;
}
.mobile-content-sec-7-box .box:first-child {
  border-left: none;
}
.mobile-content-sec-7-box .img {
  height: 44px;
}
.mobile-content-sec-7-box .star {
  padding: 20px 0;
  display: flex;
  gap: 5px;
  justify-content: center;
  align-items: center;
}

.mobile-content-sec-7-box .star svg {
  margin: 0 3px 0 0px;
  font-size: 12px;
  color: #6a7acf;
}

.mobile-content-sec-7-box .star span {
  font-size: 24px;
  font-weight: bold;
}

/* Portfolio */
.portfolio .hero-image-box {
  height: 350px;
}
.portfolio .hero-image-box-inner {
  margin: 0;
}
.portfolio-inner {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}
.portfoli-box {
  width: 32.1%;
  border: 1px solid #6c78d0;
  position: relative;
  overflow: hidden;
}
.portfolio-text {
  padding: 10px 20px;
  background: #6c78d0;
  position: absolute;
  bottom: -100%;
  width: 100%;
  transition: 0.2s all ease;
}
.portfoli-box:hover .portfolio-text {
  bottom: 0;
}
.portfolio-text h2 {
  font-size: 18px;
  color: #fff;
  padding: 0;
}

.portfolio-text p {
  padding: 0 0 7px;
  color: #fff;
  font-size: 13px;
}
.portfolio-text a {
  color: #000;
  background: #fff;
  font-size: 12px;
  padding: 3px 15px;
  border-radius: 50px;
  transition: 0.4s all ease;
}
.portfolio-text a:hover {
  background: #000;
  color: #fff;
}
.portfolio-text span {
  color: #fff;
  background: #000;
  font-size: 12px;
  padding: 3px 15px;
  border-radius: 50px;
  transition: 0.4s all ease;
  cursor: pointer;
}
.portfolio-text span:hover {
  background: #fff;
  color: #000;
}
.portfolio-big-box {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  visibility: hidden;
  transition: 0.2s all ease;
}
.portfolio-big-box-show {
  opacity: 1;
  visibility: visible;
}
.portfolio-big-box-overlay {
  background: #00000038;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  cursor: url("./assets/images/x.svg"), auto;
}

.portfolio-big-box-inner {
  position: relative;
  width: 90%;
  height: 90%;
  margin: 0 auto;
  top: 50%;
  transform: translateY(-50%) scale(0.9);
  overflow-y: scroll;
  background: #fff;
  padding: 30px;
  box-shadow: 0 0 10px #0000004f;
  left: 0;
  right: 0;
  border-radius: 20px;
  opacity: 0;
  visibility: hidden;
  transition: 0.2s all ease;
}
.portfolio-big-box-show .portfolio-big-box-inner {
  opacity: 1;
  visibility: visible;
  transform: translateY(-50%) scale(1);
}

.portfolio-big-box-inner {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
.portfolio-big-box-inner::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.portfolio-big-box-inner .content-box-top {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
}
.portfolio-big-box-inner .content-box-top .image-gallery {
  width: 70%;
}
.portfolio-big-box-inner .content-box-top .image-gallery .big-img {
  border: 1px solid #ccc;
}
.portfolio-big-box-inner .content-box-top .image-gallery .thumbs {
  display: flex;
  gap: 10px;
  padding: 15px 0 0;
  width: 100%;
  overflow-x: scroll;
}

.portfolio-big-box-inner .content-box-top .image-gallery .thumbs img {
  width: 20%;
  border: 1px solid #ccc;
  cursor: pointer;
}
.portfolio-big-box-inner .content-box-top .details-box {
  width: 27.4%;
}

.client-reviews-box {
  padding: 30px 10px 0;
}

.client-reviews-box-inner {
  border: 1px solid #ddd;
  padding: 20px;
  border-radius: 10px;
}
.review-box h5 {
  padding: 0 0 10px;
}
.client-box {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding: 20px 0 0;
}
.client-img {
  width: 70px;
}
.client-img img {
  border-radius: 200px;
}
.tns-outer {
  padding: 0 !important;
  position: relative;
}
.tns-controls {
  position: absolute;
  bottom: -40px;
  left: 0;
  right: 0;
  text-align: center;
}

.tns-controls button {
  background: url("../src/assets/images/reviews/prev.png");
  height: 30px;
  width: 30px;
  background-size: cover;
  margin: 0 5px;
  text-indent: -999999px;
}

.tns-controls button:last-child {
  background: url("../src/assets/images/reviews/next.png");
  background-size: cover;
}

.close {
  position: absolute;
  right: 20px;
  top: 10px;
  cursor: pointer;
}

@media only screen and (min-width: 320px) and (max-width: 479px) {
  .site-header .logo {
    position: relative;
  }
  .site-header .logo img {
    max-width: 100px;
    display: inline-block;
  }
  .mob-contact {
    display: block;
    float: right;
    margin: 8px auto 0;
  }
  .mob-contact a {
    display: block;
    border: 1px solid #efefef;
    padding: 5px 15px;
    border-radius: 30px;
    text-align: center;
    margin: 5px 0;
    color: #fff;
    font-size: 14px;
  }
  .mob-contact a.whatsappc {
    background: #1ab99f;
  }
  .mob-contact a.quote {
    background: #6c78d0;
  }
  .site-header {
    padding: 10px 0;
  }
  .desk-nav {
    display: none;
  }
  .mobile-nav,
  .float-icon {
    display: block;
  }

  .float-icon {
    position: fixed;
    bottom: 23px;
    z-index: 9999;
    left: 15px;
    width: 50px;
    height: 50px;
    background: #6c78d0;
    text-align: center;
    padding: 20px;
    border-radius: 100px;
  }
  .float-icon span {
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    color: #fff;
    font-size: 24px;
  }
  .mobile-nav .main-navigation {
    position: fixed;
    bottom: 70px;
    width: 300px;
    background: #fff;
    border: 1px solid #6c78d0;
    border-top: 13px solid #6c78d0;
    border-radius: 10px;
    z-index: 9999;
    margin: 0 auto;
  }
  .mobile-nav .main-navigation ul li {
    width: 100%;
  }
  .mobile-nav .main-navigation ul li a {
    display: block;
  }
  .main-navigation ul li.price_btn {
    margin: 0;
    border-radius: 0;
  }
  .main-navigation ul li.price_btn a {
    margin: 0;
    padding: 7px 15px;
    border-radius: 6px;
  }
  .notactive .main-navigation {
    visibility: hidden;
    opacity: 0;
    transition: 0.2s all ease;
    bottom: 50px;
  }
  .navactive .main-navigation {
    visibility: visible;
    opacity: 1;
    transition: 0.2s all ease;
    bottom: 75px;
  }
  .main-navigation ul li ul.sub-menu {
    position: relative;
    top: 0 !important;
    visibility: visible;
    opacity: 1;
    float: left;
  }
  .main-navigation ul li ul.sub-menu::before {
    display: none;
  }
  .main-navigation ul li.has-child span.sub-icon {
    margin-left: 0;
    position: absolute;
    top: 17px;
    right: 20px;
    z-index: 9999;
  }
  .hide-sub-menu {
    display: none;
  }
  .show-sub-menu {
    display: block;
  }

  .site-footer .footer-left {
    max-width: 100%;
    margin-right: 0;
  }
  .footer-1 .logo {
    max-width: 85px;
  }
  .foot-awards img {
    max-width: 50px;
    display: inline-block;
    margin: 12px 7px;
  }
  .foot-social .social-icons a {
    margin-right: 55px;
  }
  .site-footer .footer-right {
    width: 100%;
    float: left;
    margin-right: 0;
    margin-top: 30px !important;
  }
  .footer-right-top {
    display: block;
  }
  .footer-clients-logos {
    flex-wrap: wrap;
  }
  .footer-clients-logos img {
    max-width: 48%;
  }
  .footer-bottom .copyright,
  .footer-bottom .dcma_status {
    width: 100%;
    text-align: center;
  }
  .footer-bottom .dcma_status img {
    display: inline-block;
    padding: 10px 0 0;
  }
  .footer-bottom {
    margin: 20px 0 0;
    padding: 20px 0;
  }
  .site-footer .foot-menu ul li {
    padding: 0 0 15px;
  }
  .site-footer .foot-title {
    padding: 0 0 10px;
  }
  .whatsapp {
    bottom: 10px;
    display: none;
  }
  .sitecontent .title-hd {
    font-size: 18px;
  }
  .head-section {
    padding: 0;
  }
  .head-section .head-section-left,
  .head-section-right {
    padding: 0;
    width: 100%;
  }
  .head-section-right {
    padding: 20px 0 0;
  }
  .head-section-right-form {
    width: 100%;
    padding: 20px;
  }
  .head-section-right-form h2 {
    font-size: 28px;
  }

  .head-section .head-section-left h1 {
    font-size: 33px;
    font-weight: bold;
    line-height: 38px;
  }
  .head-section .head-section-left .get_p {
    margin: 10px 0 0;
    padding: 10px 5px;
  }
  .head-section .head-section-left .get_p a {
    padding: 9px;
    margin: 0 5px;
    font-size: 14px;
  }
  .head-section-right img {
    display: inline-block;
    max-width: 50%;
  }
  .home-about {
    margin: 20px 0 0;
  }
  .home-achiv {
    width: 50%;
    padding: 0 4px;
    margin: 0 0 20px;
  }
  .home-business {
    margin: 30px 0 0;
  }
  .home-business-box-left,
  .home-business-box-right {
    width: 100%;
  }
  .hb_service {
    width: 100%;
  }
  .home-concepts {
    margin: 20px 0 0;
  }
  .home-concepts-main {
    display: block;
  }
  .home-concepts-box-in {
    margin: 0 0 30px;
  }
  .home-design-dev-left,
  .home-design-dev-right {
    width: 100%;
  }
  .home-tabs {
    padding: 30px 0 0;
  }
  .react-tabs ul.react-tabs__tab-list li.react-tabs__tab {
    display: block;
    margin: 0 0 10px;
  }
  .react-tabs ul.react-tabs__tab-list li.react-tabs__tab::after {
    display: none;
  }
  .tab-content-bottom p {
    width: auto;
    margin: 5px 0;
  }
  .htb_icon {
    width: 25%;
    line-height: 0.8;
  }
  .htb_icon svg {
    font-size: 62px;
  }
  .email-us-btn a {
    font-size: 16px;
  }
  .email-us-btn {
    margin: 0 0 50px;
    padding: 20px 0;
  }
  .inner-page {
    padding: 20px 0 0;
  }
  .about-us-page-header {
    padding: 0px 0 0;
  }

  .about-us-page-header h1 {
    font-size: 30px;
  }
  .about-whoweare-left {
    width: 100%;
  }
  .about-whoweare-right {
    width: 100%;
    padding: 30px 0 0;
    display: block;
  }
  .awr-box {
    margin: 0 0 20px;
  }
  .about-tech-cal {
    display: block;
  }
  .about-tech-cal-box {
    margin: 0 0 65px;
  }
  .about-tech-cal-box:last-child {
    margin: 0;
  }
  .contact-page-left,
  .contact-page-right,
  .more-contact-box,
  .loaction-box {
    width: 100%;
  }
  .loaction-box {
    padding: 0 0 20px;
  }

  /* business Development */
  .hero-image-box {
    height: auto;
  }
  .hero-image-box-inner {
    display: block;
  }
  .hero-image-box-left {
    width: 100%;
  }
  .hero-image-box h1 {
    font-size: 22px;
    padding: 10px 0 0;
  }
  .hero-image-box h3,
  .hero-image-box h2 {
    font-size: 22px;
  }
  .hero-image-right {
    margin: 20px 0 0;
    width: 100%;
  }
  .business-development-top-boxes {
    margin: 40px 0 0;
    flex-wrap: wrap;
  }
  .business-development-package-box-large h2 {
    font-size: 25px;
    line-height: 1.2;
  }
  .bdpbl-in {
    padding: 20px 10px;
  }
  .bdpbl-box {
    width: 100%;
  }
  .bdpbl-box-body {
    padding: 20px;
  }
  .business-development-mid-inner {
    flex-wrap: wrap;
  }
  .business-development-mid-inner .bdmin {
    width: 100%;
    padding: 20px;
  }
  .business-development-top-boxes .box-inner {
    width: 100%;
    padding: 20px;
  }
  .box-x-2 {
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }
  .box-x-2-left {
    width: 100%;
  }
  .box-x-2-right {
    width: 100%;
  }
  .box-x-3 ul {
    columns: 1;
  }
  /* packages digital */
  .seo-packages-list-box-inner {
    flex-wrap: wrap;
  }
  .seo-packages-list-box-inner .box-1 {
    width: 100%;
    justify-content: center;
    border-bottom: 1px solid #a9a9a9;
  }
  .seo-packages-list-box-inner .box-2 {
    width: 20%;
    font-size: 12px;
    padding: 10px 6px;
  }
  .seo-packages-list-box-inner div:nth-child(2) {
    border: none;
  }
  .seo-packages-list-box-inner .btn-main-sm {
    padding: 3px 4px;
    line-height: 1;
    font-size: 10px;
  }
  .seo-package-tab-inner .left {
    width: 0;
    flex-wrap: wrap;
  }
  .seo-package-tab-inner .right {
    width: 100%;
    gap: 5px;
  }
  .seo-package-tab-inner .right .react-tabs__tab {
    width: 100%;
    font-size: 11px !important;
    text-align: center;
    line-height: 1;
    display: flex !important;
    align-content: center;
    padding: 8px 6px !important;
  }
  .seo-package-tab .tab-content .left {
    display: none;
  }
  .seo-package-tab .tab-content .right {
    width: 100%;
  }
  .seo-package-tab .tab-content .right .digi-price-tag {
    padding: 5px;
  }
  .seo-package-tab .tab-content .right .digi-price-tag:first-child {
    border: none;
  }
  .seo-package-tab .tab-content .right .digi-price-tag p {
    font-size: 10px;
    padding: 3px 5px;
  }
  .seo-package-tab .tab-content .right .digi-price-tag h4 {
    font-size: 20px;
  }
  .seo-package-tab .tab-content .right .digi-price-tag span {
    font-size: 10px;
  }

  /* mobile app development page */
  .mobile-header-content {
    flex-direction: column-reverse;
  }
  .mobile-header-left,
  .mobile-header-right,
  .mobile-content-sec-1-boxes .box,
  .mobile-content-sec-3-content .left,
  .mobile-content-sec-3-content .right,
  .mobile-content-sec-4-box .box,
  .mobile-content-sec-7-box .box,
  .mobile-content-sec-6-box .box {
    width: 100%;
  }
  .mobile-header-left h1 {
    font-size: 23px;
  }
  .mobile-header-left p {
    font-size: 16px;
  }
  .mobile-header-left .btn-main {
    padding: 10px 10px;
  }

  .sh-title .title,
  .mobile-content-sec-3-content .left .sh-title h2 {
    font-size: 24px;
  }
  .mobile-content-sec-1 {
    padding: 30px 0;
  }
  .mobile-content-sec-2 {
    margin: 0;
    padding: 20px 0;
  }
  .mobile-content-sec-3 {
    margin: 50px 0 0;
  }
  .mobile-content-sec-3-content .right {
    padding: 20px;
  }
  .mobile-content-sec-7-box {
    flex-wrap: wrap;
    padding: 0 20px;
  }
  .mobile-content-sec-7-box .box {
    border-bottom: 1px solid #ddd;
    border-left: none;
  }
  .mobile-content-sec-7-box .box:last-child {
    border: none;
  }
  .mobile-content-sec-7-box .box {
    padding: 20px 0;
  }
  .mobile-content-sec-6-box .box {
    padding: 15px;
  }
}

@media only screen and (min-width: 480px) and (max-width: 599px) {
  .site-header .logo {
    position: relative;
  }
  .site-header .logo img {
    max-width: 100px;
    display: inline-block;
  }
  .mob-contact {
    display: block;
    float: right;
    margin: 8px auto 0;
  }
  .mob-contact a {
    display: block;
    border: 1px solid #efefef;
    padding: 5px 15px;
    border-radius: 30px;
    text-align: center;
    margin: 5px 0;
    color: #fff;
    font-size: 14px;
  }
  .mob-contact a.whatsappc {
    background: #1ab99f;
  }
  .mob-contact a.quote {
    background: #6c78d0;
  }
  .site-header {
    padding: 10px 0;
  }
  .desk-nav {
    display: none;
  }
  .mobile-nav,
  .float-icon {
    display: block;
  }

  .float-icon {
    position: fixed;
    bottom: 23px;
    z-index: 9999;
    left: 15px;
    width: 50px;
    height: 50px;
    background: #6c78d0;
    text-align: center;
    padding: 20px;
    border-radius: 100px;
  }
  .float-icon span {
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    color: #fff;
    font-size: 24px;
  }
  .mobile-nav .main-navigation {
    position: fixed;
    bottom: 70px;
    width: 300px;
    background: #fff;
    border: 1px solid #6c78d0;
    border-top: 13px solid #6c78d0;
    border-radius: 10px;
    z-index: 9999;
    margin: 0 auto;
  }
  .mobile-nav .main-navigation ul li {
    width: 100%;
  }
  .mobile-nav .main-navigation ul li a {
    display: block;
  }
  .main-navigation ul li.price_btn {
    margin: 0;
    border-radius: 0;
  }
  .main-navigation ul li.price_btn a {
    margin: 0;
    padding: 7px 15px;
    border-radius: 6px;
  }
  .notactive .main-navigation {
    visibility: hidden;
    opacity: 0;
    transition: 0.2s all ease;
    bottom: 50px;
  }
  .navactive .main-navigation {
    visibility: visible;
    opacity: 1;
    transition: 0.2s all ease;
    bottom: 75px;
  }
  .main-navigation ul li ul.sub-menu {
    position: relative;
    top: 0 !important;
    visibility: visible;
    opacity: 1;
    float: left;
  }
  .main-navigation ul li ul.sub-menu::before {
    display: none;
  }
  .main-navigation ul li.has-child span.sub-icon {
    margin-left: 0;
    position: absolute;
    top: 17px;
    right: 20px;
    z-index: 9999;
  }
  .hide-sub-menu {
    display: none;
  }
  .show-sub-menu {
    display: block;
  }

  .site-footer .footer-left {
    max-width: 100%;
    margin-right: 0;
  }
  .footer-1 .logo {
    max-width: 85px;
  }
  .foot-awards img {
    max-width: 50px;
    display: inline-block;
    margin: 12px 7px;
  }
  .foot-social .social-icons a {
    margin-right: 55px;
  }
  .site-footer .footer-right {
    width: 100%;
    float: left;
    margin-right: 0;
    margin-top: 30px !important;
  }
  .footer-right-top {
    display: block;
  }
  .footer-clients-logos {
    flex-wrap: wrap;
  }
  .footer-clients-logos img {
    max-width: 48%;
  }
  .footer-bottom .copyright,
  .footer-bottom .dcma_status {
    width: 100%;
    text-align: center;
  }
  .footer-bottom .dcma_status img {
    display: inline-block;
    padding: 10px 0 0;
  }
  .footer-bottom {
    margin: 20px 0 0;
    padding: 20px 0;
  }
  .site-footer .foot-menu ul li {
    padding: 0 0 15px;
  }
  .site-footer .foot-title {
    padding: 0 0 10px;
  }
  .whatsapp {
    bottom: 10px;
    display: none;
  }
  .sitecontent .title-hd {
    font-size: 18px;
  }
  .head-section {
    padding: 0;
  }
  .head-section .head-section-left,
  .head-section-right {
    padding: 0;
    width: 100%;
  }
  .head-section-right {
    padding: 20px 0 0;
  }
  .head-section-right-form {
    width: 100%;
    padding: 20px;
  }
  .head-section-right-form h2 {
    font-size: 28px;
  }

  .head-section .head-section-left h1 {
    font-size: 33px;
    font-weight: bold;
    line-height: 38px;
  }
  .head-section .head-section-left .get_p {
    margin: 10px 0 0;
    padding: 10px 5px;
  }
  .head-section .head-section-left .get_p a {
    padding: 9px;
    margin: 0 5px;
    font-size: 14px;
  }
  .head-section-right img {
    display: inline-block;
    max-width: 50%;
  }
  .home-about {
    margin: 20px 0 0;
  }
  .home-achiv {
    width: 50%;
    padding: 0 4px;
    margin: 0 0 20px;
  }
  .home-business {
    margin: 30px 0 0;
  }
  .home-business-box-left,
  .home-business-box-right {
    width: 100%;
  }
  .hb_service {
    width: 100%;
  }
  .home-concepts {
    margin: 20px 0 0;
  }
  .home-concepts-main {
    display: block;
  }
  .home-concepts-box-in {
    margin: 0 0 30px;
  }
  .home-design-dev-left,
  .home-design-dev-right {
    width: 100%;
  }
  .home-tabs {
    padding: 30px 0 0;
  }
  .react-tabs ul.react-tabs__tab-list li.react-tabs__tab {
    display: block;
    margin: 0 0 10px;
  }
  .react-tabs ul.react-tabs__tab-list li.react-tabs__tab::after {
    display: none;
  }
  .tab-content-bottom p {
    width: auto;
    margin: 5px 0;
  }
  .htb_icon {
    width: 25%;
    line-height: 0.8;
  }
  .htb_icon svg {
    font-size: 62px;
  }
  .email-us-btn a {
    font-size: 16px;
  }
  .email-us-btn {
    margin: 0 0 50px;
    padding: 20px 0;
  }
  .inner-page {
    padding: 20px 0 0;
  }
  .about-us-page-header {
    padding: 0px 0 0;
  }

  .about-us-page-header h1 {
    font-size: 30px;
  }
  .about-whoweare-left {
    width: 100%;
  }
  .about-whoweare-right {
    width: 100%;
    padding: 30px 0 0;
    display: block;
  }
  .awr-box {
    margin: 0 0 20px;
  }
  .about-tech-cal {
    display: block;
  }
  .about-tech-cal-box {
    margin: 0 0 65px;
  }
  .about-tech-cal-box:last-child {
    margin: 0;
  }
  .contact-page-left,
  .contact-page-right,
  .more-contact-box,
  .loaction-box {
    width: 100%;
  }
  .loaction-box {
    padding: 0 0 20px;
  }

  /* business Development */
  .hero-image-box {
    height: auto;
  }
  .hero-image-box-inner {
    display: block;
  }
  .hero-image-box-left {
    width: 100%;
  }
  .hero-image-box h1 {
    font-size: 22px;
    padding: 10px 0 0;
  }
  .hero-image-box h3,
  .hero-image-box h2 {
    font-size: 22px;
  }
  .hero-image-right {
    margin: 20px 0 0;
    width: 100%;
  }
  .business-development-top-boxes {
    margin: 40px 0 0;
    flex-wrap: wrap;
  }
  .business-development-package-box-large h2 {
    font-size: 25px;
    line-height: 1.2;
  }
  .bdpbl-in {
    padding: 20px 10px;
  }
  .bdpbl-box {
    width: 100%;
  }
  .bdpbl-box-body {
    padding: 20px;
  }
  .business-development-mid-inner {
    flex-wrap: wrap;
  }
  .business-development-mid-inner .bdmin {
    width: 100%;
    padding: 20px;
  }
  .business-development-top-boxes .box-inner {
    width: 100%;
    padding: 20px;
  }
  .box-x-2 {
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }
  .box-x-2-left {
    width: 100%;
  }
  .box-x-2-right {
    width: 100%;
  }
  .box-x-3 ul {
    columns: 1;
  }

  /* packages digital */
  .seo-packages-list-box-inner {
    flex-wrap: wrap;
  }
  .seo-packages-list-box-inner .box-1 {
    width: 100%;
    justify-content: center;
    border-bottom: 1px solid #a9a9a9;
  }
  .seo-packages-list-box-inner .box-2 {
    width: 20%;
    font-size: 12px;
    padding: 10px 6px;
  }
  .seo-packages-list-box-inner div:nth-child(2) {
    border: none;
  }
  .seo-packages-list-box-inner .btn-main-sm {
    padding: 3px 4px;
    line-height: 1;
    font-size: 10px;
  }
  .seo-package-tab-inner .left {
    width: 0;
    flex-wrap: wrap;
  }
  .seo-package-tab-inner .right {
    width: 100%;
    gap: 5px;
  }
  .seo-package-tab-inner .right .react-tabs__tab {
    width: 100%;
    font-size: 11px !important;
    text-align: center;
    line-height: 1;
    display: flex !important;
    align-content: center;
    padding: 8px 6px !important;
  }
  .seo-package-tab .tab-content .left {
    display: none;
  }
  .seo-package-tab .tab-content .right {
    width: 100%;
  }
  .seo-package-tab .tab-content .right .digi-price-tag {
    padding: 5px;
  }
  .seo-package-tab .tab-content .right .digi-price-tag:first-child {
    border: none;
  }
  .seo-package-tab .tab-content .right .digi-price-tag p {
    font-size: 10px;
    padding: 3px 5px;
  }
  .seo-package-tab .tab-content .right .digi-price-tag h4 {
    font-size: 20px;
  }
  .seo-package-tab .tab-content .right .digi-price-tag span {
    font-size: 10px;
  }

  /* mobile app development page */
  .mobile-header-content {
    flex-direction: column-reverse;
  }
  .mobile-header-left,
  .mobile-header-right,
  .mobile-content-sec-1-boxes .box,
  .mobile-content-sec-3-content .left,
  .mobile-content-sec-3-content .right,
  .mobile-content-sec-4-box .box,
  .mobile-content-sec-7-box .box,
  .mobile-content-sec-6-box .box {
    width: 100%;
  }
  .mobile-header-left h1 {
    font-size: 23px;
  }
  .mobile-header-left p {
    font-size: 16px;
  }
  .mobile-header-left .btn-main {
    padding: 10px 10px;
  }

  .sh-title .title,
  .mobile-content-sec-3-content .left .sh-title h2 {
    font-size: 24px;
  }
  .mobile-content-sec-1 {
    padding: 30px 0;
  }
  .mobile-content-sec-2 {
    margin: 0;
    padding: 20px 0;
  }
  .mobile-content-sec-3 {
    margin: 50px 0 0;
  }
  .mobile-content-sec-3-content .right {
    padding: 20px;
  }
  .mobile-content-sec-7-box {
    flex-wrap: wrap;
    padding: 0 20px;
  }
  .mobile-content-sec-7-box .box {
    border-bottom: 1px solid #ddd;
    border-left: none;
  }
  .mobile-content-sec-7-box .box:last-child {
    border: none;
  }
  .mobile-content-sec-7-box .box {
    padding: 20px 0;
  }
  .mobile-content-sec-6-box .box {
    padding: 15px;
  }
}

@media only screen and (min-width: 600px) and (max-width: 767px) {
  .site-header .logo {
    position: relative;
  }
  .site-header .logo img {
    max-width: 100px;
    display: inline-block;
  }
  .mob-contact {
    display: block;
    float: right;
    margin: 8px auto 0;
  }
  .mob-contact a {
    display: inline-block;
    border: 1px solid #efefef;
    padding: 5px 15px;
    border-radius: 30px;
    text-align: center;
    margin: 15px 0 0 10px;
    color: #fff;
    font-size: 17px;
  }
  .mob-contact a.whatsappc {
    background: #1ab99f;
  }
  .mob-contact a.quote {
    background: #6c78d0;
  }
  .site-header {
    padding: 10px 0;
  }
  .desk-nav {
    display: none;
  }
  .mobile-nav,
  .float-icon {
    display: block;
  }

  .float-icon {
    position: fixed;
    bottom: 23px;
    z-index: 9999;
    left: 15px;
    width: 50px;
    height: 50px;
    background: #6c78d0;
    text-align: center;
    padding: 20px;
    border-radius: 100px;
  }
  .float-icon span {
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    color: #fff;
    font-size: 24px;
  }
  .mobile-nav .main-navigation {
    position: fixed;
    bottom: 70px;
    width: 300px;
    background: #fff;
    border: 1px solid #6c78d0;
    border-top: 13px solid #6c78d0;
    border-radius: 10px;
    z-index: 9999;
    margin: 0 auto;
  }
  .mobile-nav .main-navigation ul li {
    width: 100%;
  }
  .mobile-nav .main-navigation ul li a {
    display: block;
  }
  .main-navigation ul li.price_btn {
    margin: 0;
    border-radius: 0;
  }
  .main-navigation ul li.price_btn a {
    margin: 0;
    padding: 7px 15px;
    border-radius: 6px;
  }
  .notactive .main-navigation {
    visibility: hidden;
    opacity: 0;
    transition: 0.2s all ease;
    bottom: 50px;
  }
  .navactive .main-navigation {
    visibility: visible;
    opacity: 1;
    transition: 0.2s all ease;
    bottom: 75px;
  }
  .main-navigation ul li ul.sub-menu {
    position: relative;
    top: 0 !important;
    visibility: visible;
    opacity: 1;
    float: left;
  }
  .main-navigation ul li ul.sub-menu::before {
    display: none;
  }
  .main-navigation ul li.has-child span.sub-icon {
    margin-left: 0;
    position: absolute;
    top: 17px;
    right: 20px;
    z-index: 9999;
  }
  .hide-sub-menu {
    display: none;
  }
  .show-sub-menu {
    display: block;
  }

  .site-footer .footer-left {
    max-width: 100%;
    margin-right: 0;
  }
  .footer-1 .logo {
    max-width: 85px;
  }
  .foot-awards img {
    max-width: 50px;
    display: inline-block;
    margin: 12px 7px;
  }
  .foot-social .social-icons a {
    margin-right: 55px;
  }
  .site-footer .footer-right {
    width: 100%;
    float: left;
    margin-right: 0;
    margin-top: 30px !important;
  }
  .footer-clients-logos img {
    max-width: 24%;
  }
  .footer-bottom .copyright,
  .footer-bottom .dcma_status {
    width: 100%;
    text-align: center;
  }
  .footer-bottom .dcma_status img {
    display: inline-block;
    padding: 10px 0 0;
  }
  .footer-bottom {
    margin: 20px 0 0;
    padding: 20px 0;
  }
  .site-footer .foot-menu ul li {
    padding: 0 0 15px;
  }
  .site-footer .foot-title {
    padding: 0 0 10px;
  }
  .whatsapp {
    bottom: 10px;
    display: none;
  }
  .sitecontent .title-hd {
    font-size: 18px;
  }
  .head-section {
    padding: 0;
  }
  .head-section .head-section-left,
  .head-section-right {
    padding: 0;
  }
  .head-section-right {
    width: 46%;
  }
  .head-section-right-form {
    width: 100%;
    padding: 20px;
  }
  .head-section-right-form h2 {
    font-size: 24px;
  }

  .head-section .head-section-left h1 {
    font-size: 33px;
    font-weight: bold;
    line-height: 38px;
  }
  .head-section .head-section-left .get_p {
    margin: 10px 0 0;
    padding: 10px 5px;
  }
  .head-section .head-section-left .get_p a {
    padding: 9px;
    margin: 0 5px;
    font-size: 14px;
  }
  .head-section-right img {
    display: inline-block;
    max-width: 50%;
  }
  .home-about {
    margin: 20px 0 0;
  }
  .home-achiv {
    width: 50%;
    padding: 0 4px;
    margin: 0 0 20px;
  }
  .home-business {
    margin: 30px 0 0;
  }
  .home-business-box-left,
  .home-business-box-right {
    width: 100%;
  }

  .home-concepts {
    margin: 20px 0 0;
  }
  .home-concepts-main {
    display: block;
  }
  .home-concepts-box {
    width: 50%;
    float: left;
  }
  .home-concepts-box-in {
    margin: 0 0 30px;
  }
  .home-design-dev-left,
  .home-design-dev-right {
    width: 100%;
  }
  .home-tabs {
    padding: 30px 0 0;
  }
  .react-tabs ul.react-tabs__tab-list li.react-tabs__tab::after {
    display: none;
  }
  .tab-content-bottom p {
    width: auto;
    margin: 5px 0;
  }
  .htb_icon {
    width: 25%;
    line-height: 0.8;
  }
  .htb_icon svg {
    font-size: 62px;
  }
  .email-us-btn a {
    font-size: 16px;
  }
  .email-us-btn {
    margin: 0 0 50px;
    padding: 20px 0;
  }
  .inner-page {
    padding: 20px 0 0;
  }
  .about-us-page-header {
    padding: 0px 0 0;
  }

  .about-us-page-header h1 {
    font-size: 30px;
  }
  .about-whoweare-left {
    width: 100%;
  }
  .about-whoweare-right {
    width: 100%;
    padding: 30px 0 0;
    display: block;
  }
  .awr-box {
    margin: 0 0 20px;
  }
  .about-tech-cal {
    display: block;
  }
  .about-tech-cal-box {
    margin: 0 0 65px;
  }
  .about-tech-cal-box:last-child {
    margin: 0;
  }
  .contact-page-left,
  .contact-page-right,
  .more-contact-box,
  .loaction-box {
    width: 100%;
  }
  .loaction-box {
    padding: 0 0 20px;
  }
  .contact-page-left .form-group {
    width: 48%;
    float: left;
    margin: 0 10px 0 0;
  }
  .more-contact .more-contact-box,
  .locations .loaction-box {
    width: 50%;
  }
  /* business Development */
  .hero-image-box {
    height: auto;
  }
  .hero-image-box-inner {
    display: block;
  }
  .hero-image-box-left {
    width: 100%;
  }
  .hero-image-box h1 {
    font-size: 22px;
    padding: 10px 0 0;
  }
  .hero-image-box h3,
  .hero-image-box h2 {
    font-size: 22px;
  }
  .hero-image-right {
    margin: 20px 0 0;
    width: 100%;
  }
  .hero-image-right-form .form-control {
    width: 50%;
  }
  .business-development-top-boxes {
    margin: 40px 0 0;
    flex-wrap: wrap;
    gap: 10px;
  }
  .business-development-top-boxes .box-inner {
    width: 32.1%;
    padding: 15px;
  }
  .business-development-top-boxes .box-inner h2 {
    font-size: 20px;
  }
  .business-development-package-box-large h2 {
    font-size: 25px;
    line-height: 1.2;
  }
  .business-development-mid-inner {
    gap: 10px;
  }
  .bdpbl-in {
    padding: 20px 10px;
  }
  .bdpbl-box {
    width: 48%;
  }
  .bdpbl-box-body {
    padding: 20px;
  }
  .business-development-mid-inner .bdmin {
    width: 33%;
    padding: 20px 10px;
  }
  .business-development-mid-inner .bdmin-body h4 {
    font-size: 18px;
  }
  .business-development-top-boxes .box-inner {
    width: 100%;
    padding: 20px;
  }
  .box-x-2 {
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }
  .box-x-2-left {
    width: 100%;
  }
  .box-x-2-right {
    width: 100%;
  }
  .box-x-3 ul {
    columns: 1;
  }

  /* packages digital */
  .seo-packages-list-box-inner {
    flex-wrap: wrap;
  }
  .seo-packages-list-box-inner .box-1 {
    width: 100%;
    justify-content: center;
    border-bottom: 1px solid #a9a9a9;
  }
  .seo-packages-list-box-inner .box-2 {
    width: 20%;
    padding: 10px 6px;
  }
  .seo-packages-list-box-inner div:nth-child(2) {
    border: none;
  }
  .seo-packages-list-box-inner .btn-main-sm {
    padding: 3px 4px;
    line-height: 1;
  }
  .seo-package-tab-inner .left {
    width: 0;
    flex-wrap: wrap;
  }
  .seo-package-tab-inner .right {
    width: 100%;
    gap: 5px;
  }
  .seo-package-tab-inner .right .react-tabs__tab {
    width: 100%;
    text-align: center;
    line-height: 1;
    display: flex !important;
    align-content: center;
    padding: 8px 6px !important;
  }
  .seo-package-tab .tab-content .left {
    display: none;
  }
  .seo-package-tab .tab-content .right {
    width: 100%;
  }
  .seo-package-tab .tab-content .right .digi-price-tag {
    padding: 5px;
  }
  .seo-package-tab .tab-content .right .digi-price-tag:first-child {
    border: none;
  }
  .seo-package-tab .tab-content .right .digi-price-tag p {
    padding: 3px 5px;
  }
  .seo-package-tab .tab-content .right .digi-price-tag h4 {
    font-size: 28px;
  }

  /* mobile app development page */
  .mobile-content-sec-1-boxes .box,
  .mobile-content-sec-4-box .box,
  .mobile-content-sec-7-box .box,
  .mobile-content-sec-6-box .box {
    width: 47%;
  }
  .mobile-content-sec-3-content .left,
  .mobile-content-sec-3-content .right {
    width: 100%;
  }
  .mobile-header-right {
    width: 38%;
  }
  .mobile-header-left h1 {
    font-size: 23px;
  }
  .mobile-header-left p {
    font-size: 16px;
  }
  .mobile-header-left .btn-main {
    padding: 10px 10px;
  }

  .sh-title .title,
  .mobile-content-sec-3-content .left .sh-title h2 {
    font-size: 24px;
  }
  .mobile-content-sec-1 {
    padding: 30px 0;
  }
  .mobile-content-sec-2 {
    margin: 0;
    padding: 20px 0;
  }
  .mobile-content-sec-3 {
    margin: 50px 0 0;
  }
  .mobile-content-sec-3-content .right {
    padding: 20px;
  }
  .mobile-content-sec-7-box {
    flex-wrap: wrap;
    padding: 0 20px;
  }
  .mobile-content-sec-7-box .box {
    border-bottom: 1px solid #ddd;
    border-left: none;
  }
  .mobile-content-sec-7-box .box:last-child {
    border: none;
  }
  .mobile-content-sec-7-box .box {
    padding: 20px 0;
  }
  .mobile-content-sec-6-box .box {
    padding: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .site-header .logo {
    position: relative;
  }
  .site-header .logo img {
    max-width: 100px;
    display: inline-block;
  }
  .mob-contact {
    display: block;
    float: right;
    margin: 8px auto 0;
  }
  .mob-contact a {
    display: inline-block;
    border: 1px solid #efefef;
    padding: 5px 15px;
    border-radius: 30px;
    text-align: center;
    margin: 15px 0 0 10px;
    color: #fff;
    font-size: 17px;
  }
  .mob-contact a.whatsappc {
    background: #1ab99f;
  }
  .mob-contact a.quote {
    background: #6c78d0;
  }
  .site-header {
    padding: 10px 0;
  }
  .desk-nav {
    display: none;
  }
  .mobile-nav,
  .float-icon {
    display: block;
  }

  .float-icon {
    position: fixed;
    bottom: 23px;
    z-index: 9999;
    left: 15px;
    width: 50px;
    height: 50px;
    background: #6c78d0;
    text-align: center;
    padding: 20px;
    border-radius: 100px;
  }
  .float-icon span {
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    color: #fff;
    font-size: 24px;
  }
  .mobile-nav .main-navigation {
    position: fixed;
    bottom: 70px;
    width: 300px;
    background: #fff;
    border: 1px solid #6c78d0;
    border-top: 13px solid #6c78d0;
    border-radius: 10px;
    z-index: 9999;
    margin: 0 auto;
  }
  .mobile-nav .main-navigation ul li {
    width: 100%;
  }
  .mobile-nav .main-navigation ul li a {
    display: block;
  }
  .main-navigation ul li.price_btn {
    margin: 0;
    border-radius: 0;
  }
  .main-navigation ul li.price_btn a {
    margin: 0;
    padding: 7px 15px;
    border-radius: 6px;
  }
  .notactive .main-navigation {
    visibility: hidden;
    opacity: 0;
    transition: 0.2s all ease;
    bottom: 50px;
  }
  .navactive .main-navigation {
    visibility: visible;
    opacity: 1;
    transition: 0.2s all ease;
    bottom: 75px;
  }
  .main-navigation ul li ul.sub-menu {
    position: relative;
    top: 0 !important;
    visibility: visible;
    opacity: 1;
    float: left;
  }
  .main-navigation ul li ul.sub-menu::before {
    display: none;
  }
  .main-navigation ul li.has-child span.sub-icon {
    margin-left: 0;
    position: absolute;
    top: 17px;
    right: 20px;
    z-index: 9999;
  }
  .hide-sub-menu {
    display: none;
  }
  .show-sub-menu {
    display: block;
  }

  .site-footer .footer-left {
    max-width: 100%;
    margin-right: 0;
  }
  .footer-1 .logo {
    max-width: 85px;
  }
  .foot-awards img {
    max-width: 50px;
    display: inline-block;
    margin: 12px 7px;
  }
  .foot-social .social-icons a {
    margin-right: 55px;
  }
  .site-footer .footer-right {
    width: 100%;
    float: left;
    margin-right: 0;
    margin-top: 30px !important;
  }
  .footer-clients-logos img {
    max-width: 24%;
  }
  .footer-bottom .copyright,
  .footer-bottom .dcma_status {
    width: 100%;
    text-align: center;
  }
  .footer-bottom .dcma_status img {
    display: inline-block;
    padding: 10px 0 0;
  }
  .footer-bottom {
    margin: 20px 0 0;
    padding: 20px 0;
  }
  .site-footer .foot-menu ul li {
    padding: 0 0 15px;
  }
  .site-footer .foot-title {
    padding: 0 0 10px;
  }
  .whatsapp {
    bottom: 10px;
    display: none;
  }
  .sitecontent .title-hd {
    font-size: 18px;
  }
  .head-section {
    padding: 0;
  }
  .head-section .head-section-left,
  .head-section-right {
    padding: 0;
  }
  .head-section-right {
    width: 46%;
  }
  .head-section-right-form {
    width: 100%;
    padding: 20px;
  }
  .head-section-right-form h2 {
    font-size: 24px;
  }

  .head-section .head-section-left h1 {
    font-size: 33px;
    font-weight: bold;
    line-height: 38px;
  }
  .head-section .head-section-left .get_p {
    margin: 10px 0 0;
    padding: 10px 5px;
  }
  .head-section .head-section-left .get_p a {
    padding: 9px;
  }
  .head-section-right img {
    display: inline-block;
    max-width: 50%;
  }
  .home-about {
    margin: 20px 0 0;
  }
  .home-achiv {
    padding: 0 4px;
    margin: 0 0 20px;
  }
  .home-achiv-in {
    padding: 10px;
  }
  .home-business {
    margin: 30px 0 0;
  }
  .home-business-box-left,
  .home-business-box-right {
    width: 100%;
  }
  .home-business-box-right {
    display: none;
  }

  .home-concepts {
    margin: 20px 0 0;
  }
  .home-concepts-main {
    display: block;
  }
  .home-concepts-box {
    width: 33%;
    float: left;
  }
  .home-concepts-box-in {
    margin: 0 0 30px;
  }
  .home-design-dev-left,
  .home-design-dev-right {
    width: 100%;
  }
  .home-design-dev-left {
    display: none;
  }
  .home-tabs {
    padding: 30px 0 0;
  }
  .react-tabs ul.react-tabs__tab-list li.react-tabs__tab::after {
    display: none;
  }
  .tab-content-bottom p {
    width: auto;
    margin: 5px 0;
  }
  .htb_icon svg {
    font-size: 62px;
  }
  .email-us-btn a {
    font-size: 16px;
  }
  .email-us-btn {
    margin: 0 0 50px;
    padding: 20px 0;
  }
  .inner-page {
    padding: 20px 0 0;
  }
  .about-us-page-header {
    padding: 0px 0 0;
  }

  .about-us-page-header h1 {
    font-size: 30px;
  }
  .about-whoweare-left {
    width: 100%;
  }
  .about-whoweare-right {
    width: 100%;
    padding: 30px 0 0;
    display: block;
  }
  .awr-box {
    margin: 0 0 20px;
  }
  .about-tech-cal {
    display: block;
  }
  .about-tech-cal-box {
    margin: 0 0 65px;
  }
  .about-tech-cal-box:last-child {
    margin: 0;
  }
  .contact-page-left,
  .contact-page-right,
  .more-contact-box,
  .loaction-box {
    width: 100%;
  }
  .loaction-box {
    padding: 0 0 20px;
  }
  .contact-page-left .form-group {
    width: 48%;
    float: left;
    margin: 0 10px 0 0;
  }
  .more-contact .more-contact-box,
  .locations .loaction-box {
    width: 50%;
  }
  /* business Development */
  .hero-image-box {
    height: auto;
  }
  .hero-image-box-inner {
    margin: 20px 0;
  }
  .hero-image-box-left {
    width: 100%;
  }
  .hero-image-box h1 {
    font-size: 22px;
    padding: 10px 0 0;
  }
  .hero-image-box h3,
  .hero-image-box h2 {
    font-size: 22px;
  }
  .hero-image-right {
    margin: 20px 0 0;
    width: 100%;
  }
  .business-development-top-boxes {
    margin: 40px 0 0;
    flex-wrap: wrap;
    gap: 10px;
  }
  .business-development-top-boxes .box-inner {
    width: 32.1%;
    padding: 15px;
  }
  .business-development-top-boxes .box-inner h2 {
    font-size: 20px;
  }
  .business-development-package-box-large h2 {
    font-size: 25px;
    line-height: 1.2;
  }
  .business-development-mid-inner {
    gap: 10px;
  }
  .bdpbl-in {
    padding: 20px 10px;
  }
  .bdpbl-box {
    width: 48%;
  }
  .bdpbl-box-body {
    padding: 20px;
  }
  .business-development-mid-inner .bdmin {
    width: 33%;
    padding: 20px 10px;
  }
  .business-development-mid-inner .bdmin-body h4 {
    font-size: 18px;
  }

  /* packages digital */
  .seo-packages-list-box-inner {
    flex-wrap: wrap;
  }
  .seo-packages-list-box-inner .box-1 {
    width: 100%;
    justify-content: center;
    border-bottom: 1px solid #a9a9a9;
  }
  .seo-packages-list-box-inner .box-2 {
    width: 20%;
    padding: 10px 6px;
  }
  .seo-packages-list-box-inner div:nth-child(2) {
    border: none;
  }
  .seo-packages-list-box-inner .btn-main-sm {
    padding: 3px 4px;
    line-height: 1;
  }
  .seo-package-tab-inner .left {
    width: 0;
    flex-wrap: wrap;
  }
  .seo-package-tab-inner .right {
    width: 100%;
    gap: 5px;
  }
  .seo-package-tab-inner .right .react-tabs__tab {
    width: 100%;
    text-align: center;
    line-height: 1;
    display: flex !important;
    align-content: center;
    padding: 8px 6px !important;
  }
  .seo-package-tab .tab-content .left {
    display: none;
  }
  .seo-package-tab .tab-content .right {
    width: 100%;
  }
  .seo-package-tab .tab-content .right .digi-price-tag {
    padding: 5px;
  }
  .seo-package-tab .tab-content .right .digi-price-tag:first-child {
    border: none;
  }
  .seo-package-tab .tab-content .right .digi-price-tag p {
    padding: 3px 5px;
  }
  .seo-package-tab .tab-content .right .digi-price-tag h4 {
    font-size: 28px;
  }

  /* mobile app development page */
  .mobile-content-sec-1-boxes .box,
  .mobile-content-sec-4-box .box,
  .mobile-content-sec-7-box .box,
  .mobile-content-sec-6-box .box {
    width: 47%;
  }
  .mobile-content-sec-3-content .left,
  .mobile-content-sec-3-content .right {
    width: 100%;
  }
  .mobile-header-right {
    width: 38%;
  }
  .mobile-header-left h1 {
    font-size: 23px;
  }
  .mobile-header-left p {
    font-size: 16px;
  }
  .mobile-header-left .btn-main {
    padding: 10px 10px;
  }

  .sh-title .title,
  .mobile-content-sec-3-content .left .sh-title h2 {
    font-size: 24px;
  }
  .mobile-content-sec-1 {
    padding: 30px 0;
  }
  .mobile-content-sec-2 {
    margin: 0;
    padding: 20px 0;
  }
  .mobile-content-sec-3 {
    margin: 50px 0 0;
  }
  .mobile-content-sec-3-content .right {
    padding: 20px;
  }
  .mobile-content-sec-7-box {
    flex-wrap: wrap;
    padding: 0 20px;
  }
  .mobile-content-sec-7-box .box {
    border-bottom: 1px solid #ddd;
    border-left: none;
  }
  .mobile-content-sec-7-box .box:last-child {
    border: none;
  }
  .mobile-content-sec-7-box .box {
    padding: 20px 0;
  }
  .mobile-content-sec-6-box .box {
    padding: 15px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1024px) {
  .site-header .logo {
    position: relative;
  }
  .site-header .logo img {
    max-width: 100px;
    display: inline-block;
  }
  .mob-contact {
    display: block;
    float: right;
    margin: 8px auto 0;
  }
  .mob-contact a {
    display: inline-block;
    border: 1px solid #efefef;
    padding: 5px 15px;
    border-radius: 30px;
    text-align: center;
    margin: 15px 0 0 10px;
    color: #fff;
    font-size: 17px;
  }
  .mob-contact a.whatsappc {
    background: #1ab99f;
  }
  .mob-contact a.quote {
    background: #6c78d0;
  }
  .site-header {
    padding: 10px 0;
  }
  .desk-nav {
    display: none;
  }
  .mobile-nav,
  .float-icon {
    display: block;
  }

  .float-icon {
    position: fixed;
    bottom: 23px;
    z-index: 9999;
    left: 15px;
    width: 50px;
    height: 50px;
    background: #6c78d0;
    text-align: center;
    padding: 20px;
    border-radius: 100px;
  }
  .float-icon span {
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    color: #fff;
    font-size: 24px;
  }
  .mobile-nav .main-navigation {
    position: fixed;
    bottom: 70px;
    width: 300px;
    background: #fff;
    border: 1px solid #6c78d0;
    border-top: 13px solid #6c78d0;
    border-radius: 10px;
    z-index: 9999;
    margin: 0 auto;
  }
  .mobile-nav .main-navigation ul li {
    width: 100%;
  }
  .mobile-nav .main-navigation ul li a {
    display: block;
  }
  .main-navigation ul li.price_btn {
    margin: 0;
    border-radius: 0;
  }
  .main-navigation ul li.price_btn a {
    margin: 0;
    padding: 7px 15px;
    border-radius: 6px;
  }
  .notactive .main-navigation {
    visibility: hidden;
    opacity: 0;
    transition: 0.2s all ease;
    bottom: 50px;
  }
  .navactive .main-navigation {
    visibility: visible;
    opacity: 1;
    transition: 0.2s all ease;
    bottom: 75px;
  }
  .main-navigation ul li ul.sub-menu {
    position: relative;
    top: 0 !important;
    visibility: visible;
    opacity: 1;
    float: left;
  }
  .main-navigation ul li ul.sub-menu::before {
    display: none;
  }
  .main-navigation ul li.has-child span.sub-icon {
    margin-left: 0;
    position: absolute;
    top: 17px;
    right: 20px;
    z-index: 9999;
  }
  .hide-sub-menu {
    display: none;
  }
  .show-sub-menu {
    display: block;
  }

  .site-footer .footer-left {
    max-width: 100%;
    margin-right: 0;
  }
  .footer-1 .logo {
    max-width: 85px;
  }
  .foot-awards img {
    max-width: 50px;
    display: inline-block;
    margin: 12px 7px;
  }
  .foot-social .social-icons a {
    margin-right: 55px;
  }
  .site-footer .footer-right {
    width: 100%;
    float: left;
    margin-right: 0;
    margin-top: 30px !important;
  }
  .footer-clients-logos img {
    max-width: 24%;
  }
  .footer-bottom .copyright,
  .footer-bottom .dcma_status {
    width: 100%;
    text-align: center;
  }
  .footer-bottom .dcma_status img {
    display: inline-block;
    padding: 10px 0 0;
  }
  .footer-bottom {
    margin: 20px 0 0;
    padding: 20px 0;
  }
  .site-footer .foot-menu ul li {
    padding: 0 0 15px;
  }
  .site-footer .foot-title {
    padding: 0 0 10px;
  }
  .whatsapp {
    bottom: 10px;
    display: none;
  }
  .sitecontent .title-hd {
    font-size: 18px;
  }
  .head-section {
    padding: 0;
  }
  .head-section .head-section-left,
  .head-section-right {
    padding: 0;
  }
  .head-section-right {
    width: 46%;
  }
  .head-section-right-form {
    width: 100%;
    padding: 20px;
  }
  .head-section-right-form h2 {
    font-size: 24px;
  }

  .head-section .head-section-left h1 {
    font-size: 33px;
    font-weight: bold;
    line-height: 38px;
  }
  .head-section .head-section-left .get_p {
    margin: 10px 0 0;
    padding: 10px 5px;
  }
  .head-section .head-section-left .get_p a {
    padding: 9px;
  }
  .head-section-right img {
    display: inline-block;
    max-width: 50%;
  }
  .home-about {
    margin: 20px 0 0;
  }
  .home-achiv {
    padding: 0 4px;
    margin: 0 0 20px;
  }
  .home-achiv-in {
    padding: 10px;
  }
  .home-business {
    margin: 30px 0 0;
  }
  .home-business-box-left,
  .home-business-box-right {
    width: 100%;
  }
  .home-business-box-right {
    display: none;
  }

  .home-concepts {
    margin: 20px 0 0;
  }
  .home-concepts-main {
    display: block;
  }
  .home-concepts-box {
    width: 33%;
    float: left;
  }
  .home-concepts-box-in {
    margin: 0 0 30px;
  }
  .home-design-dev-left,
  .home-design-dev-right {
    width: 100%;
  }
  .home-design-dev-left {
    display: none;
  }
  .home-tabs {
    padding: 30px 0 0;
  }
  .react-tabs ul.react-tabs__tab-list li.react-tabs__tab::after {
    display: none;
  }
  .tab-content-bottom p {
    width: auto;
    margin: 5px 0;
  }
  .htb_icon svg {
    font-size: 62px;
  }
  .email-us-btn a {
    font-size: 16px;
  }
  .email-us-btn {
    margin: 0 0 50px;
    padding: 20px 0;
  }
  .inner-page {
    padding: 20px 0 0;
  }
  .about-us-page-header {
    padding: 0px 0 0;
  }

  .about-us-page-header h1 {
    font-size: 30px;
  }
  .about-whoweare-left {
    width: 100%;
  }
  .about-whoweare-right {
    width: 100%;
    padding: 30px 0 0;
    display: block;
  }
  .awr-box {
    margin: 0 0 20px;
  }
  .about-tech-cal {
    display: block;
  }
  .about-tech-cal-box {
    margin: 0 0 65px;
  }
  .about-tech-cal-box:last-child {
    margin: 0;
  }
  .contact-page-left,
  .contact-page-right,
  .more-contact-box,
  .loaction-box {
    width: 100%;
  }
  .loaction-box {
    padding: 0 0 20px;
  }
  .contact-page-left .form-group {
    width: 48%;
    float: left;
    margin: 0 10px 0 0;
  }
  .more-contact .more-contact-box,
  .locations .loaction-box {
    width: 50%;
  }
  /* business Development */
  .hero-image-box {
    height: auto;
  }
  .hero-image-box-inner {
    margin: 20px 0;
  }
  .hero-image-box-left {
    width: 100%;
  }
  .hero-image-box h1 {
    font-size: 22px;
    padding: 10px 0 0;
  }
  .hero-image-box h3,
  .hero-image-box h2 {
    font-size: 22px;
  }
  .hero-image-right {
    margin: 20px 0 0;
    width: 100%;
  }
  .business-development-top-boxes {
    margin: 40px 0 0;
    flex-wrap: wrap;
    gap: 10px;
  }
  .business-development-top-boxes .box-inner {
    width: 32.1%;
    padding: 15px;
  }
  .business-development-top-boxes .box-inner h2 {
    font-size: 20px;
  }
  .business-development-package-box-large h2 {
    font-size: 25px;
    line-height: 1.2;
  }
  .business-development-mid-inner {
    gap: 10px;
  }
  .bdpbl-in {
    padding: 20px 10px;
  }
  .bdpbl-box {
    width: 48%;
  }
  .bdpbl-box-body {
    padding: 20px;
  }
  .business-development-mid-inner .bdmin {
    width: 33%;
    padding: 20px 10px;
  }
  .business-development-mid-inner .bdmin-body h4 {
    font-size: 18px;
  }

  /* mobile app development page */

  .mobile-header-right {
    width: 38%;
  }
  .mobile-content-sec-6-box .box {
    width: 31%;
  }
  .mobile-content-sec-1-boxes {
    gap: 20px;
  }
  .mobile-header-left h1 {
    font-size: 23px;
  }
  .mobile-header-left p {
    font-size: 16px;
  }
  .mobile-header-left .btn-main {
    padding: 10px 10px;
  }

  .sh-title .title,
  .mobile-content-sec-3-content .left .sh-title h2 {
    font-size: 24px;
  }
  .mobile-content-sec-1 {
    padding: 30px 0;
  }
  .mobile-content-sec-2 {
    margin: 0;
    padding: 20px 0;
  }
  .mobile-content-sec-3 {
    margin: 50px 0 0;
  }
  .mobile-content-sec-3-content .right {
    padding: 20px;
  }
  .mobile-content-sec-7-box .box {
    padding: 20px 0;
  }
  .mobile-content-sec-6-box .box {
    padding: 15px;
  }
}
